<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <!-- headerPicture -->
    <div class="heardePicture">
      <div class="heardePicture_text">
        <h1>安全智慧管理平台</h1>
        <p>SECURITY AMART MANAGEMENT PLATFORM</p>
        <p>
          安全智慧管理平台致力于构建安全的公共服务体系，运用数据分析及全方位监控系统，建立面向车辆、驾驶员、乘客三大安全防线的公交安全防控体系，全方位保障公交行车安全。
        </p>
      </div>
      <div class="heardePicture_menu">
        <ul>
          <li @click="selectPage_1">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据云平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据云平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_2">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/安全平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/安全平台-选中.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_3">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_4">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/APP出行平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/APP出行平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_5">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/充电桩-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/充电桩.png" alt=""
            /></span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 智慧安全平台 -->
    <div class="securityPlatform">
      <section id="about-us" class="about-us">
        <!-- 标题 -->
        <h2 class="title1">安全智慧管理平台</h2>
        <!-- 补充说明区域 -->
        <p class="intro">建立可靠的公交安全防控系统，保障公交安全体系</p>
        <!-- 公司业务容器，栅格布局 -->
        <div class="features">
          <!-- 公司业务 -->
          <div class="feature">
            <!-- 左侧业务图标 -->
            <img
              src="../../assets/image/产品服务/安全平台/监控-icon.png"
              alt=""
            />
            <!-- 右侧业务标题 -->
            <h4 class="feature-title">全面的实时监控</h4>
            <!-- 右侧业务内容 -->
            <p class="feature-content">
              全面的公交车实时智能化监控平台，主动安全驾驶监控辅助，及时进行防碰撞报警及盲区监测，对事故进行公交智能化防控，降低事故率
            </p>
          </div>
          <div class="feature">
            <img
              src="@/assets/image/产品服务/安全平台/安全事故分析-icon.png"
              alt=""
            />
            <h4 class="feature-title">安全事故分析</h4>
            <p class="feature-content">
              利用视频监测行为分析、大数据分析等技术，记录收集驾驶员行为数据及服务数据，从安全事故及数据两大维度，构建全面、立体的驾驶员画像
            </p>
          </div>
          <div class="feature">
            <img
              src="@/assets/image/产品服务/安全平台/车辆安全监测-icon.png"
              alt=""
            />
            <h4 class="feature-title">车辆安全监测</h4>
            <p class="feature-content">
              建设更加安全综合监管功能的高整合性安全管理平台，为管理人员提供安全监督和管理手段，能够有效了解客车运行状况
            </p>
          </div>
          <div class="feature">
            <img
              src="@/assets/image/产品服务/安全平台/保险理赔-icon.png"
              alt=""
            />
            <h4 class="feature-title">保险理赔管理</h4>
            <p class="feature-content">
              利用驾驶员和车辆安全管理平台进行大数据统计分析，与车辆保险方案相结合，有效改善公交事故，防范事故于未然，为司机和企业提供安全保障
            </p>
          </div>
        </div>
      </section>
    </div>
    <!-- 实时监控 -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="@/assets/image/产品服务/安全平台/实时监控-配图.png"
          alt=""
        />
        <div class="realTime_title">
          <h2>实时监控</h2>
          <!-- <img src="../../assets/image/产品服务/安全平台/标题-bg.png" alt=""> -->
        </div>
        <p class="realTime_content">
          搭建基于驾驶员画像的智能监管平台，利用视频监测行为分析、大数据分析等技术，记录收集驾驶员行为数据（如疲劳驾驶、接打电话、分身驾驶、抽烟行为）及服务数据（如驾驶平稳度、车次准点率、车次到站率、乘客投诉率等）
        </p>
      </div>
    </div>
    <!-- 安全事故 -->
    <div class="securityAccident">
      <div class="securityAccident_title">
        <h2>安全事故</h2>
        <!-- <img src="../../assets/image/产品服务/安全平台/标题-bg.png" alt=""> -->
      </div>
      <p class="securityAccident_content">
        对安全事故进行全方位分析，对事故进行公交智能化防控，从而在行驶环节中起到规范管理作用，为公交管理人员提供安全监督和管理手段，能够有效了解公交运行状况，降低事故率
      </p>
      <img src="@/assets/image/产品服务/安全平台/安全事故-配图.png" alt="" />
    </div>
    <!-- 保险理赔 -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="@/assets/image/产品服务/安全平台/保险理赔-配图.png"
          alt=""
        />
        <div class="realTime_title">
          <h2>保险理赔</h2>
          <!-- <img src="../../assets/image/产品服务/安全平台/标题-bg.png" alt=""> -->
        </div>
        <p class="realTime_content">
          公交车出险及理赔全方位分析，对事故进行公交智能化防控，分析出险率及保费相关信息，减少出险，降低保费，降低公交公司运营成本
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chooseItem: ''
    }
  },
  methods: {
    selectPage_1() {
      this.$router.push('/productService')
      window.scrollTo(0, 0)
    },
    selectPage_2() {
      this.$router.push('/securityPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_3() {
      this.$router.push('/monitoringPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_4() {
      this.$router.push('/travelApp')
      window.scrollTo(0, 0)
    },
    selectPage_5() {
      this.$router.push('/chargingPile')
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="less" scoped>
.heardePicture {
  position: relative;
  width: 100%;
  height: 1000px;
  background: url('../../assets/image/产品服务/安全平台/安全平台banner.png');
  .heardePicture_text {
    position: absolute;
    top: 270px;
    left: 285px;
    width: 480px;
    h1 {
      font-size: 60px;
      color: #fff;
    }
    p {
      font-size: 24px;
      margin-bottom: 70px;
      color: #fefefe;
    }
  }

  .heardePicture_menu {
    position: fixed;
    z-index: 999;
    top: 222px;
    right: 100px;
    ul {
      width: 124px;
      li {
        width: 124px;
        text-align: center;
      }
    }
  }
}
.securityPlatform {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 所有section都按grid布局 */
section {
  display: grid;
  /* 单元格都居中对齐 */
  justify-items: center;
  max-width: 1320px;
  padding: 0 80px;
}

/* 区域大标题 */
.title1 {
  margin-top: 100px;
  /* justify-self: center; */
  /* position: relative; */
  font-size: 36px;
  color: var(--text-color-darker);
}
.intro {
  font-size: 24px;
  color: #999;
}
/* 关于我们 */
.about-us {
  padding-bottom: 32px;
}

/* 公司业务布局 */
.features {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 127px 200px;
  column-gap: 5vw;
  align-items: center;
}

/* 业务 */
.feature {
  display: grid;
  /* 规划栅格，图标占两行，标题和内容各占一行 */
  grid-template-areas:
    'img title'
    'img content';
  /* 每列的宽度，第一列60象素，第二列占满剩余空间 */
  grid-template-columns: 120px 1fr;
  /* 三行 */
  grid-template-rows: 1fr 2fr;
}

/* 图标 */
.feature img {
  grid-area: img;
}

/* 标题 */
.feature-title {
  grid-area: title;
  font-size: 28px;
  color: #333;
}

/* 内容 */
.feature-content {
  grid-area: content;
  font-size: 18px;
  color: #999;
  margin-top: 8px;
}

.realTime {
  background: #f3f3f8;
  padding: 80px 0;
  .realTime_box {
    display: grid;
    /* 规划栅格，图标占两行，标题和内容各占一行 */
    grid-template-areas:
      'img title'
      'img content';
    /* 每列的宽度，第一列60象素，第二列占满剩余空间 */
    grid-template-columns: 900px 1fr;
    /* 三行 */
    grid-template-rows: 1fr 2fr;
    margin-left: 200px;
    .realTimeP {
      grid-area: img;
    }
    .realTime_title {
      h2 {
        font-size: 36px;
        color: #111;
        margin-top: 66px;
      }
      grid-area: title;
      background: url('../../assets/image/产品服务/安全平台/标题-bg.png')
        no-repeat;
    }
    .realTime_content {
      width: 537px;
      grid-area: content;
      font-size: 24px;
      color: #666;
      line-height: 40px;
    }
  }
}
.securityAccident {
  display: grid;
  /* 规划栅格，图标占两行，标题和内容各占一行 */
  grid-template-areas:
    'title img '
    'content img ';
  /* 每列的宽度，第一列60象素，第二列占满剩余空间 */
  grid-template-columns: 700px 1fr;
  /* 三行 */
  grid-template-rows: 1fr 2fr;
  margin-left: 250px;
  margin-top: 160px;
  img {
    grid-area: img;
  }
  .securityAccident_title {
    h2 {
      font-size: 36px;
      color: #111;
      margin-top: 66px;
    }
    grid-area: title;
    background: url('../../assets/image/产品服务/安全平台/标题-bg.png')
      no-repeat;
  }
  .securityAccident_content {
    width: 537px;
    grid-area: content;
    font-size: 24px;
    color: #666;
    line-height: 40px;
  }
}
</style>
